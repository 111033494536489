import { isPlainObject as _isPlainObject } from 'is-plain-object';
import i18n from '@/plugins/i18n';
import _isEqual from 'lodash.isequal';
import _isFunction from 'lodash.isfunction';
import _isEmpty from 'lodash.isempty';

export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

export function isPlainObject(value) {
  return _isPlainObject(value);
}

export function isEqual(value, other) {
  return _isEqual(value, other);
}

export function isFunction(value) {
  return _isFunction(value);
}

export function isEmpty(value) {
  return _isEmpty(value);
}

export function download(filename, blob) {
  const link = document.createElement('a');
  link.download = filename;
  link.href = URL.createObjectURL(blob);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const repackResponseFile = (response, fallbackFilename) => {
  const content = response.data;
  const type = response.headers['content-type'];
  const disposition = response.headers['content-disposition'];

  const correctDisposition = disposition?.replaceAll('?', '_');

  const blob = new Blob([content], { type });

  const splittedDisposition = correctDisposition?.split('filename=')[1];

  const filename = splittedDisposition
    ? decodeURIComponent(splittedDisposition)?.replaceAll('"', '')
    : fallbackFilename;

  return { blob, filename };
};

export function mergeIfPropertyEmpty(object, source) {
  return Object.keys(object).reduce((result, key) => {
    if (object[key] !== '' && object[key] !== 0 && !object[key] && source[key]) {
      return { ...result, [key]: source[key] };
    }

    return { ...result, [key]: object[key] };
  }, {});
}

export function getServiceType(item) {
  return i18n.t(item?.serviceType?.name) || item.service;
}

export function isEmptyObject(obj) {
  if (typeof obj !== 'object') return false;

  const objValues = Object.values(obj);
  for (let i = 0; i < objValues.length; i += 1) {
    if (Array.isArray(objValues[i]) && objValues[i].length) return false;
    if (objValues[i]) return false;
  }

  return true;
}
