import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { canAbility } from './middleware';

export default {
  path: '/contractors',
  name: routes.CONTRACTORS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.CONTRACTORS_LAYOUT },
  children: [
    {
      path: '/contractors',
      name: routes.CONTRACTORS_LAYOUT,
      component: () => import('@/layouts/Contractors.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTORS)],
      },
      redirect: { name: routes.CONTRACTORS, query: { page: 1 } },
      children: [
        {
          path: '',
          name: routes.CONTRACTORS,
          component: () => import('@/views/Contractors/index.vue'),
          props: { isArchive: false },
        },
      ],
    },
    {
      path: '/contractors/:id',
      name: routes.CONTRACTORS_DETAILED,
      component: () => import('@/views/Contractors/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.CONTRACTORS)],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
  ],
};
