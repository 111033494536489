import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translateService from '@/services/translate';

Vue.use(VueI18n);

const defaultLanguage = translateService.getDefaultLanguage();

const loadedLanguages = [];

const i18n = new VueI18n({
  locale: defaultLanguage,
  messages: {},
  missing: () => {},
});

export default i18n;

export function setI18nTranslate(message, lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);

  if (loadedLanguages.includes(lang)) return lang;

  i18n.setLocaleMessage(lang, message);
  loadedLanguages.push(lang);
  return lang;
}
