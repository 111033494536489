import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import * as entityStatuses from '@/constants/entityStatuses';
import { canAbility } from './middleware';

export default {
  path: '/employees',
  name: routes.EMPLOYEES_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.EMPLOYEES },
  children: [
    {
      path: '/employees',
      name: routes.EMPLOYEES,
      component: () => import('@/views/Employees/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ status: entityStatuses.ACTIVE, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/employees/archive',
      name: routes.EMPLOYEES_ARCHIVE,
      component: () => import('@/views/Employees/index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ status: entityStatuses.ARCHIVED, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/employees/roles',
      name: routes.ROLES,
      component: () => import('@/views/Employees/Roles/Index.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ status: entityStatuses.SHOW_ROLES, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/employees/roles/create',
      name: routes.ROLES_CREATE,
      component: () => import('@/views/Employees/Roles/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ isEdit: false, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/employees/roles/:id/edit',
      name: routes.ROLES_EDIT,
      component: () => import('@/views/Employees/Roles/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ isEdit: true, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/employees/roles/:id',
      name: routes.ROLES_DETAILED,
      component: () => import('@/views/Employees/Roles/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query.prevPage || 1 }),
    },
    {
      path: '/employees/:id/edit',
      name: routes.EMPLOYEES_EDIT,
      component: () => import('@/views/Employees/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.EMPLOYEES)],
      },
      props: route => ({ isEdit: true, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/employees/create',
      name: routes.EMPLOYEES_CREATE,
      component: () => import('@/views/Employees/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.EMPLOYEES)],
      },
      props: route => ({ isEdit: false, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/employees/:id',
      name: routes.EMPLOYEES_DETAILED,
      component: () => import('@/views/Employees/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.EMPLOYEES)],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query.prevPage }),
    },
  ],
};
