/* eslint-disable */
import { isObject } from '@/utils/common';
import { clone } from '@/utils/clone';

/**
 * @param {string} str
 * @example
 * snakeToCamel("foo_bar") // return fooBar
 */
export function snakeToCamel(str) {
  return str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );
}

/**
 *
 * @param {object} tempObj
 * @returns {object}
 * @example
 * snakeCaseKeysToCamelCase({ foo_bar: 'baz', bar_foo: { foo_bar: 'baz' } }) // return { fooBar: 'baz', barFoo: { fooBar: 'baz' } }
 */
export function snakeCaseKeysToCamelCase(value) {
  if (Array.isArray(value)) {
    return value.map(snakeCaseKeysToCamelCase);
  }

  if (isObject(value)) {
    return Object.entries(value).reduce((result, [key, keyValue]) => {
      const newKey = snakeToCamel(key);
      const newKeyValue = snakeCaseKeysToCamelCase(keyValue);
      result[newKey] = newKeyValue;
      return result;
    }, {});
  }

  return value;
}

/**
 * @param {string} str
 * @example
 * camelToSnake("fooBar") // return foo_bar
 */
export function camelToSnake(str) {
  return str?.replace(/([A-Z])/g, function($1) {
    return '_' + $1.toLowerCase();
  });
}

export function camelCaseKeysToSnakeCase(value) {
  if (Array.isArray(value)) {
    return value.map(camelCaseKeysToSnakeCase);
  }

  if (isObject(value)) {
    return Object.entries(value).reduce((result, [key, keyValue]) => {
      const newKey = camelToSnake(key);
      const newKeyValue = camelCaseKeysToSnakeCase(keyValue);
      result[newKey] = newKeyValue;
      return result;
    }, {});
  }

  return value;
}

/**
 *
 * @param {string} string
 * @param {object} param1
 */
export function middleTruncate(string, { maxLength = 15, separator } = {}) {
  if (string.length <= maxLength) return string;

  const originSeparator = separator || '...';
  const left = Math.floor(maxLength / 2);
  const right = Math.ceil(maxLength / 2);

  return string.substr(0, left) + originSeparator + string.substr(-right);
}
