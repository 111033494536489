import { getSubdomain } from '@/utils/http';

export function getBaseUrlWithoutApiAndProtocol() {
  if (process.env.NODE_ENV === 'production') {
    return `${getSubdomain()}.${process.env.VUE_APP_BACKEND_API_URL}`;
  }

  return '';
}

export function getBaseUrlWithoutApi() {
  if (process.env.NODE_ENV === 'production') {
    return `${process.env.VUE_APP_PROTOCOL}://${getBaseUrlWithoutApiAndProtocol()}`;
  }

  return '';
}

export default function getBaseUrl() {
  return `${getBaseUrlWithoutApi()}/api/v2`;
}
