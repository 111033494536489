import * as routes from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';
import { accessToCalendar } from './middleware';

export default {
  path: '/calendar',
  name: routes.CALENDAR,
  component: () => import('@/views/Calendar/index.vue'),
  meta: {
    middleware: [accessToCalendar()],
  },
  props: route => ({ status: entityStatuses.ACTIVE, page: Number(route.query.page) || 1 }),
};
