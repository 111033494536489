/* eslint-disable */
import client from '@/http/client';
import { loadStorageItem, saveStorageItem } from './storage';

const BASE_URL = '/translations';
const STORAGE_LANGUAGE_KEY = 'language';

class TranslateService {
  /**
   * @param {Object} param0
   * @param {number} param0.limit
   * @param {number} param0.offset
   */
  async getLanguages({ limit, offset } = {}) {
    return (
      await client.get(`${BASE_URL}/languages/`, {
        _withoutAuthHeader: true,
        params: {
          limit,
          offset,
        },
      })
    ).results;
  }

  /**
   * @param {Object} param0
   * @param {number} param0.languageId
   * @param {number} param0.limit
   * @param {number} param0.offset
   */
  async getTranslations({ languageId }) {
    const translationsList = [];
    let offset = 0;

    while (true) {
      const { results, next } = await client.get(`${BASE_URL}/phrase-translations/`, {
        _withoutAuthHeader: true,
        params: {
          languageId,
          limit: 1000,
          offset,
        },
      });

      offset += results.length;
      translationsList.push(...results);

      if (!next) {
        break;
      }
    }

    return translationsList;
  }

  /**
   * @returns {string}
   */
  getBrowserLanguage() {
    const language = window.navigator
      ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
      : 'en-US';

    return language.split('-')[0];
  }

  /**
   * @returns {string}
   */
  getDefaultLanguage() {
    const storageLanguage = loadStorageItem(STORAGE_LANGUAGE_KEY);

    if (storageLanguage) {
      return storageLanguage;
    }

    return this.getBrowserLanguage();
  }

  /**
   * @param {string} language
   */
  setDefaultLanguage(language) {
    saveStorageItem(STORAGE_LANGUAGE_KEY, language);
  }

  translationsArrayToObject(translations) {
    return translations.reduce((result, translationModel) => {
      const _result = Object.assign({}, result);
      _result[translationModel.phraseTitle] = translationModel.translation;

      return _result;
    }, {});
  }
}

export default new TranslateService();
