import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { canAbility, accessToRooms } from './middleware';

export default {
  path: '/projects',
  name: routes.PROJECTS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.PROJECTS },
  children: [
    {
      path: '/projects',
      name: routes.PROJECTS_LAYOUT,
      component: () => import('@/layouts/Projects.vue'),
      redirect: { name: routes.PROJECTS, query: { page: 1 } },
      meta: {
        middleware: [canAbility([actions.READ], subjects.PROJECTS)],
      },
      children: [
        {
          path: '/projects',
          name: routes.PROJECTS,
          component: () => import('@/views/Projects/index.vue'),
          props: { isArchive: false },
        },
        {
          path: '/projects/archive',
          name: routes.PROJECTS_ARCHIVE,
          component: () => import('@/views/Projects/index.vue'),
          props: { isArchive: true },
        },
      ],
    },
    {
      path: '/projects/create',
      name: routes.PROJECTS_CREATE,
      component: () => import('@/views/Projects/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS)],
      },
      props: { isEdit: false },
    },
    {
      path: '/projects/:id/edit',
      name: routes.PROJECTS_EDIT,
      component: () => import('@/views/Projects/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS)],
      },
      props: { isEdit: true },
    },
    {
      path: '/projects/:id',
      name: routes.PROJECTS_DETAILED,
      component: () => import('@/views/Projects/Detailed.vue'),
    },
    {
      path: '/units/create',
      name: routes.UNITS_CREATE,
      component: () => import('@/views/Units/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS)],
      },
      props: { isEdit: false },
    },
    {
      path: '/units/:id/edit',
      name: routes.UNITS_EDIT,
      component: () => import('@/views/Units/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS)],
      },
      props: { isEdit: true },
    },
    {
      path: '/units/:id',
      name: routes.UNITS_DETAILED,
      component: () => import('@/views/Units/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.PROJECTS)],
      },
    },
    {
      path: '/rooms/create',
      name: routes.ROOMS_CREATE,
      component: () => import('@/views/Rooms/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS), accessToRooms()],
      },
      props: { isEdit: false },
    },
    {
      path: '/rooms/:id/edit',
      name: routes.ROOMS_EDIT,
      component: () => import('@/views/Rooms/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS), accessToRooms()],
      },
      props: { isEdit: true },
    },
    {
      path: '/rooms/:id',
      name: routes.ROOMS_DETAILED,
      component: () => import('@/views/Rooms/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.PROJECTS), accessToRooms()],
      },
    },
    {
      path: '/buildings/create',
      name: routes.BUILDINGS_CREATE,
      component: () => import('@/views/Buildings/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS)],
      },
      props: { isEdit: false },
    },
    {
      path: '/buildings/:id/edit',
      name: routes.BUILDINGS_EDIT,
      component: () => import('@/views/Buildings/Modify.vue'),
      meta: {
        middleware: [canAbility([actions.UPDATE], subjects.PROJECTS)],
      },
      props: { isEdit: true },
    },
    {
      path: '/buildings/:id',
      name: routes.BUILDINGS_DETAILED,
      component: () => import('@/views/Buildings/Detailed.vue'),
      meta: {
        middleware: [canAbility([actions.READ], subjects.PROJECTS)],
      },
    },
  ],
};
