import * as types from './types';

export default {
  [types.SET_LANGUAGES_LIST]: (state, languagesList) => {
    state.localeList.instance = languagesList;
  },

  [types.SET_SELECTED_LANGUAGE]: (state, language) => {
    state.localeModel.instance = language;
  },

  [types.LIST_LOADED]: state => {
    state.localeList.loading = false;
  },

  [types.LIST_LOADING]: state => {
    state.localeList.loading = true;
  },
};
