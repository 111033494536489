import Vue from 'vue';
import Vuex from 'vuex';

import locale from './modules/locale';
import user from './modules/user';
import chat from './modules/chat';
import common from './modules/common';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    locale,
    user,
    chat,
    common,
  },
});
