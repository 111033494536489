import chatService from '@/services/chat';
import * as types from './types';

import { SET_CLIENT_ROOM, SET_UNREAD_COUNT, SET_EMPLOYEE_ROOMS, SET_PARTICIPANTS } from '../mutations/types';

export default {
  [types.SET_CLIENT_ROOM]: ({ commit }, roomData) => {
    commit(SET_CLIENT_ROOM, roomData.rooms[0].roomId);
    commit(SET_UNREAD_COUNT, roomData.rooms[0].unreadMessageCount);
    commit(SET_PARTICIPANTS, roomData.rooms[0].participants);
  },

  [types.SET_EMPLOYEE_ROOM]: ({ commit }, roomData) => {
    commit(
      SET_EMPLOYEE_ROOMS,
      roomData.rooms.map(room => chatService.normalizeRoomData(room))
    );
    commit(SET_UNREAD_COUNT, roomData.unreadChatCount);
  },
};
