import '@/polyfills';

import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import authService from '@/services/auth';
import analyticsService from '@/services/analytics';
import initializeHttpInterceptors from '@/http/interceptors';

import '@/assets/scss/base.scss';
import vuetify from '@/plugins/vuetify';
import i18n from '@/plugins/i18n';
import sentry from '@/plugins/sentry';

import '@/plugins/vee-validate';
import '@/plugins/casl';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.config.productionTip = false;

const { VUE_APP_SENTRY_DSN, NODE_ENV } = process.env;
if (NODE_ENV === 'production' && VUE_APP_SENTRY_DSN) {
  Vue.use(sentry, {
    dsn: VUE_APP_SENTRY_DSN,
  });
}

analyticsService.init(store);

// init auth service
authService.restoreAuthTokens();

// init http client interceptors
initializeHttpInterceptors();
store.dispatch('user/initUserRole');

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
