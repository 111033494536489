/* eslint-disable */
import client from '@/http/client';

const BASE_URL = '/users';

class ProfileService {
  async get() {
    // @todo Добавить /users/clients/ после реализации
    return client.get(`${BASE_URL}/me/`);
  }
}

export default new ProfileService();
