import errorService from '@/services/error';
import { ERROR_STATUSES } from '@/constants/http';

// eslint-disable-next-line import/prefer-default-export
export function handleError(error) {
  const status = error?.response?.status;

  if (
    (status === ERROR_STATUSES.NOT_FOUND || status === ERROR_STATUSES.INTERNAL_SERVER_ERROR) &&
    !error?.response?.config?.ignoreError
  ) {
    return errorService.throwError(status);
  }

  return Promise.reject(error);
}
