import { SET_OFFLINE, SET_ONLINE, SET_CONNECTED } from './types';

export default {
  [SET_ONLINE]: state => {
    state.onLine = true;
  },

  [SET_OFFLINE]: state => {
    state.onLine = false;
  },

  [SET_CONNECTED]: (state, status) => {
    state.wsIsConnected = status;
  },
};
