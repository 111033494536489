import { addRequestInterceptor, addResponseInterceptor } from '../client';
import { requestWithAuth, handleAuthError } from './auth';
import { requestCamelToSnake, responseSnakeToCamel } from './formatting';
import { handleError } from './error';

export default function initializeHttpInterceptors() {
  addRequestInterceptor({ request: requestWithAuth });
  addResponseInterceptor({ error: handleAuthError });
  addRequestInterceptor({ request: requestCamelToSnake });
  addResponseInterceptor({ response: responseSnakeToCamel });
  addResponseInterceptor({ error: handleError });
}
