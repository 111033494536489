import * as routes from '@/constants/routes';
// import * as subjects from '@/constants/subjects';
// import * as actions from '@/constants/actions';
import * as entityStatuses from '@/constants/entityStatuses';
import { accessToRequests } from './middleware';

export default {
  path: '/requests',
  name: routes.REQUESTS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.REQUESTS },
  children: [
    {
      path: '/requests',
      name: routes.REQUESTS,
      component: () => import('@/views/Requests/index.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({ status: entityStatuses.ACTIVE, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/requests/archive',
      name: routes.REQUESTS_ARCHIVE,
      component: () => import('@/views/Requests/index.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({ status: entityStatuses.ARCHIVED, page: Number(route.query.page) || 1 }),
    },
    {
      path: '/requests/create',
      name: routes.REQUESTS_CREATE,
      component: () => import('@/views/Requests/Modify.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({
        isEdit: false,
        category: +route.query?.category || null,
        categoryName: route.query?.categoryName,
        prevPage: +route.query?.prevPage || 1,
      }),
    },
    {
      path: '/requests/:id',
      name: routes.REQUESTS_DETAILED,
      component: () => import('@/views/Requests/Detailed.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({ id: +route.params.id, prevPage: +route.query.prevPage || 1 }),
    },
    {
      path: '/requests/:id/edit',
      name: routes.REQUESTS_EDIT,
      component: () => import('@/views/Requests/Modify.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({ isEdit: true, id: +route.params.id, prevPage: +route.query?.prevPage || 1 }),
    },
    {
      path: '/requests/category-type/create',
      name: routes.REQUESTS_TYPE_CREATE,
      component: () => import('@/views/Requests/ModifyType.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({
        isEdit: false,
        prevPage: +route.query?.prevPage || 1,
      }),
    },
    {
      path: '/requests/category-type/:id/edit',
      name: routes.REQUESTS_TYPE_EDIT,
      component: () => import('@/views/Requests/ModifyType.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({
        isEdit: true,
        id: +route.params.id,
        prevPage: +route.query?.prevPage || 1,
      }),
    },
    {
      path: '/requests/category/create',
      name: routes.REQUESTS_CATEGORY_CREATE,
      component: () => import('@/views/Requests/ModifyCategory.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({
        isEdit: false,
        prevPage: +route.query?.prevPage || 1,
      }),
    },
    {
      path: '/requests/category/:id/edit',
      name: routes.REQUESTS_CATEGORY_EDIT,
      component: () => import('@/views/Requests/ModifyCategory.vue'),
      meta: {
        middleware: [accessToRequests()],
      },
      props: route => ({
        isEdit: true,
        id: +route.params.id,
        prevPage: +route.query?.prevPage || 1,
      }),
    },
  ],
};
