import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import store from '@/store';

export const install = (Vue, { dsn }) => {
  Sentry.init({
    dsn,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    beforeSend: event => {
      const { user } = store.state;
      // eslint-disable-next-line no-param-reassign
      event.user = {
        ...(event.user || {}),
        email: user?.user?.email,
        role: user?.role,
      };
      return event;
    },
  });
};

export default {
  install,
};
