import { localeChanged } from 'vee-validate';
import translateService from '@/services/translate';
import { setI18nTranslate } from '@/plugins/i18n';
import * as types from './types';
import { LIST_LOADED, LIST_LOADING, SET_LANGUAGES_LIST, SET_SELECTED_LANGUAGE } from '../mutations/types';

export default {
  [types.SELECT_LANGUAGE]: async ({ commit }, language) => {
    commit(SET_SELECTED_LANGUAGE, language);
    translateService.setDefaultLanguage(language.code);

    try {
      const translations = await translateService.getTranslations({ languageId: language.id });
      setI18nTranslate(translateService.translationsArrayToObject(translations), language.code);
      // @todo добавить загрузку языка на бэк, если пользователь авторизован
    } catch {
      // @todo добавить обработку ошибок
    }

    localeChanged();
  },

  [types.FETCH_LANGUAGES_LIST]: async ({ commit, dispatch }) => {
    commit(LIST_LOADING);

    try {
      const languages = await translateService.getLanguages();
      commit(SET_LANGUAGES_LIST, languages);

      const defaultLanguageCode = translateService.getDefaultLanguage();
      const defaultLanguage = languages.find(
        language => language.code.toLowerCase() === defaultLanguageCode.toLowerCase()
      );

      if (defaultLanguage) {
        await dispatch(types.SELECT_LANGUAGE, defaultLanguage);
        return;
      }

      await dispatch(types.SELECT_LANGUAGE, languages[0]);
      translateService.setDefaultLanguage(languages[0].code);
    } catch (err) {
      // @todo добавить обработку ошибок
    } finally {
      commit(LIST_LOADED);
    }
  },
};
