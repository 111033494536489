import { camelCaseKeysToSnakeCase, snakeCaseKeysToCamelCase } from '@/utils/formatters';

export function requestCamelToSnake(config) {
  return {
    ...config,
    data: camelCaseKeysToSnakeCase(config.data),
    params: camelCaseKeysToSnakeCase(config.params),
  };
}

export function responseSnakeToCamel(response) {
  return { ...response, data: snakeCaseKeysToCamelCase(response.data) };
}
