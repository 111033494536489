import { AUTH_HEADER, ERROR_STATUSES } from '@/constants/http';
import client from '../client';
import authService from '../../services/auth';

export function requestWithAuth({ _withoutAuthHeader = false, ...config }) {
  if (_withoutAuthHeader) {
    return config;
  }

  const additionalHeaders = {
    [AUTH_HEADER]: authService.getAuthHeader(),
  };

  return {
    ...config,
    headers: {
      ...config.headers,
      ...additionalHeaders,
    },
  };
}

export function handleAuthError(error) {
  const errorStatus = error?.response?.status;
  const isIgnoreAuthError = error?.response?.config?.ingoreAuthError;

  if (errorStatus === ERROR_STATUSES.UNAUTHORIZED && !isIgnoreAuthError) {
    const { _retry } = error.config;

    if (!_retry) {
      return authService
        .refreshAuthTokens()
        .then(() =>
          client.request({
            ...error.config,
            headers: {
              ...error.config.headers,
            },
            _retry: true,
          })
        )
        .catch(requestError => {
          if (requestError.response.status === ERROR_STATUSES.UNAUTHORIZED) {
            authService.removeAuthTokens();
          }

          throw requestError;
        });
    }
  }

  return Promise.reject(error);
}
