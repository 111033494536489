import { ACCESS_DENIED } from '@/constants/routes';
import abilityFor from '@/config/ability';

export default function canAbility(actions, subject) {
  return ({ next, store }) => {
    const rights = store.getters['user/rights'];
    const abilities = abilityFor(store.state.user.role, rights);

    if (actions.some(action => abilities.can(action, subject))) {
      return next();
    }

    return next({ name: ACCESS_DENIED });
  };
}
