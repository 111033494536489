import { LOGIN } from '@/constants/routes';
import { FETCH_USER } from '@/store/modules/user/actions/types';

export default function loadProfile({ next, store }) {
  const isProfileLoaded = Object.keys(store.state.user.user).length > 0;

  if (!isProfileLoaded) {
    return store
      .dispatch(`user/${FETCH_USER}`)
      .then(() => {
        next();
      })
      .catch(() => {
        return next({ name: LOGIN });
      });
  }

  return next();
}
