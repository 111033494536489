class ErrorService {
  events = [];

  subscribe(cb) {
    this.events.push(cb);
  }

  unsubscribe(cb) {
    this.events = this.events.filter(eventCb => eventCb !== cb);
  }

  throwError(code) {
    this.events.forEach(eventCb => eventCb(code));
  }
}

export default new ErrorService();
