import authService from '@/services/auth';
import profileService from '@/services/profile';
import { saveStorageItem, loadStorageItem } from '@/services/storage';
import { GUEST } from '@/constants/roles';
import * as types from './types';
import { SET_USER_ROLE, SET_USER } from '../mutations/types';

const APP_ROLE = 'app:role';

export default {
  [types.SET_USER_ROLE]({ commit }, role) {
    commit(SET_USER_ROLE, role);

    saveStorageItem(APP_ROLE, role);
  },

  [types.INIT_USER_ROLE]({ commit }) {
    const role = loadStorageItem(APP_ROLE) || 'guest';

    commit(SET_USER_ROLE, role);
  },

  [types.LOGOUT]({ commit }) {
    commit(SET_USER_ROLE, GUEST);
    commit(SET_USER, {});

    authService.logout();
  },

  [types.FETCH_USER]({ commit }) {
    return profileService.get().then(user => {
      commit(SET_USER, user);
    });
  },
};
