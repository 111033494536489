import mixpanel from 'mixpanel-browser';
import { getSubdomain } from '@/utils/http';

const TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN;
const companyName = getSubdomain();

export default {
  store: null,
  init(store) {
    mixpanel.init(TOKEN);
    this.store = store;
  },

  track(action, data = {}) {
    if (this.store) {
      try {
        const actionData = {};
        const { email, role } = this.store.state.user?.user || {};

        if (email) {
          actionData.Email = email;
        }

        if (role) {
          actionData.Role = role;
        }

        mixpanel.track(action, {
          ...actionData,
          Company: companyName,
          ...data,
        });
      } catch (error) {
        console.error(error);
      }
    }
  },

  createChangeLanguagePayload(code) {
    return { Language: code };
  },

  createCardAddingPayload(status) {
    return { 'Status binding': status };
  },

  createChatViewingPayload(client) {
    return { Client: client };
  },

  createSendingMessageWithFilePayload(client, filesCount) {
    const data = { 'Files count': filesCount };

    if (client) {
      data.Client = client;
    }

    return data;
  },

  createSendingMessagePayload(client) {
    return client ? { Client: client } : {};
  },

  createLoginPayload(email, role) {
    return { Email: email, Role: role };
  },

  createNewsSortingPayload(sortingParametrs) {
    return { 'Sorting parametrs': sortingParametrs };
  },

  createInvoicesListSortingPayload(sortingParametrs) {
    return { 'Sorting parametrs': sortingParametrs };
  },

  createInvoicesListFilteringPayload(filters) {
    return { 'Filtering paramentrs': filters };
  },

  createInvoicesDetailedPayload(invoiceNumber) {
    return { 'Invoice number': invoiceNumber };
  },

  createPaymentPayload(status) {
    return { 'Status payment': status };
  },
};
