import * as types from './types';

export default {
  [types.SET_UNREAD_COUNT]: (state, unreadCount) => {
    state.unreadCount = unreadCount;
  },

  [types.SET_EMPLOYEE_ROOMS]: (state, employeeRooms) => {
    state.employeeRoomList = employeeRooms;
  },

  [types.SET_CLIENT_ROOM]: (state, clientRoomId) => {
    state.clientRoomId = clientRoomId;
  },

  [types.SET_PARTICIPANTS]: (state, participants) => {
    state.participants = participants;
  },

  [types.SET_SELECTED_CHAT]: (state, chat) => {
    state.selectedChat = chat;
  },
};
