import * as routes from '@/constants/routes';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';
import { canAbility } from './middleware';

export default {
  path: '/assets',
  name: routes.ASSETS_PARENT,
  component: { render: h => h('router-view') },
  redirect: { name: routes.ASSETS },
  children: [
    {
      path: '/assets',
      name: routes.ASSETS_LAYOUT,
      component: () => import('@/layouts/Assets.vue'),
      redirect: { name: routes.ASSETS, query: { page: 1 } },
      meta: {
        middleware: [canAbility([actions.READ], subjects.ASSETS)],
      },
      children: [
        {
          path: '/assets',
          name: routes.ASSETS,
          component: () => import('@/views/Assets/index.vue'),
          props: { isArchive: false },
        },
        {
          path: '/assets/archive',
          name: routes.ASSETS_ARCHIVE,
          component: () => import('@/views/Assets/index.vue'),
          props: { isArchive: true },
        },
      ],
    },
  ],
};
