<template>
  <div id="app" class="app">
    <div v-if="localeIsLoading" class="app__loader">
      <v-progress-circular indeterminate />
    </div>

    <router-view v-else />
  </div>
</template>

<script>
// Constants
import { FETCH_LANGUAGES_LIST } from '@/store/modules/locale/actions/types';

export default {
  name: 'App',

  data: () => ({
    media: {
      isMobile: false,
      md: false,
      sm: false,
      xs: false,
    },
  }),

  computed: {
    localeIsLoading() {
      return this.$store.getters.languagesListLoading;
    },
  },

  watch: {
    '$vuetify.breakpoint': {
      immediate: true,
      deep: true,
      handler(breakpoint) {
        this.media.isMobile = breakpoint.sm || breakpoint.xs;
        this.media.md = breakpoint.md;
        this.media.sm = breakpoint.sm;
        this.media.xs = breakpoint.xs;
      },
    },
  },

  mounted() {
    this.$store.dispatch('user/initUserRole');
    this.$store.dispatch(FETCH_LANGUAGES_LIST);
  },

  provide() {
    return {
      media: this.media,
    };
  },
};
</script>

<style lang="scss">
.app {
  &__loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: $--primary-color;
  }
}
</style>
