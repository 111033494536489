import Vue from 'vue';
import { abilitiesPlugin } from '@casl/vue';
import abilityFor from '@/config/ability';
import { loadStorageItem } from '@/services/storage';
import { GUEST } from '@/constants/roles';
import store from '@/store';

const role = loadStorageItem('app:role') || GUEST;
const rights = store.getters['user/rights'];

Vue.use(abilitiesPlugin, abilityFor(role, rights), {
  useGlobalProperties: true,
});
