export default {
  selectedLanguage({ localeModel }) {
    return localeModel.instance;
  },

  languagesList({ localeList }) {
    return localeList.instance;
  },

  languagesListLoading({ localeList }) {
    return localeList.loading;
  },
};
