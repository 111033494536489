import * as types from './types';

export default {
  [types.SET_USER_ROLE]: (state, role) => {
    state.role = role;
  },

  [types.SET_USER]: (state, user) => {
    if (user.id === 99) {
      user.sections?.push({ name: 'assets', type: 'read' }, { name: 'assets', type: 'write' });
    }
    state.user = user;
  },
};
