import { ACCESS_DENIED } from '@/constants/routes';

export default function accessToRooms() {
  return ({ next, store }) => {
    const access = store.getters['user/companySectionsRights'];

    if (access.find(item => item.name === 'rooms')) {
      return next();
    }

    return next({ name: ACCESS_DENIED });
  };
}
