/* eslint-disable  */
import client from '@/http/client';
import authService from '@/services/auth';
import { getBaseUrlWithoutApiAndProtocol } from '@/http/getBaseURL';
import { camelCaseKeysToSnakeCase } from '@/utils/formatters';
import { format } from 'date-fns';
import WS from '@/utils/websocket';

const BASE_URL = '/chat';

const types = {
  SWITCH_ROOM: 'enter_room',
  SEND_MESSAGE: 'send_message',
  NEW_MESSAGE: 'new_message',
  READ_MESSAGE: 'read_message',
  LEAVE_CHAT: 'leave_chat',
  TRANSLATE_MESSAGE: 'translate_message',
};

class ChatService {
  /** @type {WS} */
  socket = null;
  lastMessages = [];
  currentRoom = null;

  constructor() {
    this.connected = false;
    this.subscribers = {
      messages: [],
      clientRooms: [],
      employeeRooms: [],
    };
  }

  async connect() {
    return new Promise((resolve, reject) => {
      if (this.socket) {
        this.socket.wsInstance.close();
      }

      const protocol = process.env.VUE_APP_PROTOCOL.toLowerCase() === 'https' ? 'wss' : 'ws';

      this.currentRoom = null;

      this.socket = new WS(
        process.env.NODE_ENV === 'production'
          ? `${protocol}://${getBaseUrlWithoutApiAndProtocol()}/ws/chat/?token=${authService.access}`
          : `${protocol}://${document.location.host}/ws/chat/?token=${authService.access}`
      );

      this.socket.on(
        'open',
        () => {
          this.connected = true;
          resolve();
        },
        { once: true }
      );

      this.socket.on(
        'error',
        () => {
          this.connected = false;
          reject();
        },
        { once: true }
      );
    });
  }

  disconnect() {
    if (this.connected) {
      this.socket.close();
      this.connected = false;
    }
  }

  leaveChat() {
    this.socket.send(types.LEAVE_CHAT);
  }

  getClientRoom() {
    return client.get(`${BASE_URL}/clients/room/`);
  }

  /**
   * @private
   * @param {Object | Array} payload
   */
  send(payload) {
    if (this.connected) {
      this.socket.send(JSON.stringify(camelCaseKeysToSnakeCase(payload)));
    }
  }

  switchRoom(room) {
    this.currentRoom = room;
    this.socket.send(types.SWITCH_ROOM, { room });
  }

  async getEmployeeRooms() {
    const rooms = await client.get(`${BASE_URL}/employees/rooms/`);

    const newRooms = await Promise.all(
      rooms.results.map(async room => {
        return this.normalizeRoomData(room);
      })
    );

    rooms.results = newRooms;

    return rooms;
  }

  normalizeRoomData(room) {
    return {
      unreadCount: room.unreadMessageCount,
      id: room.roomId,
      participants: room.participants,
      user: {
        id: room?.client?.id || room.clientId,
        name: room?.clientName || `${room.client.firstName} ${room.client.lastName}`,
        units: room?.client?.units,
      },
    };
  }

  formatMessage(message) {
    const messageDate = new Date(message.createdAt);
    return {
      id: message.id,
      authorId: message.authorId,
      name: message.authorName,
      text: message.text,
      date: format(messageDate, 'dd.LL HH:mm'),
      isRead: message.isRead,
      attachedFiles: message.files.map(file => {
        const name = decodeURI(file.url)
          .split('/')
          .pop();
        return { ...file, name };
      }),
    };
  }

  async getMessages({ roomId, limit, offset }, config = {}) {
    const messages = (
      await client.get(`${BASE_URL}/messages/`, {
        ...config,
        params: {
          roomId,
          limit,
          offset,
        },
      })
    ).results;

    return messages
      .map(message => {
        return this.formatMessage(message);
      })
      .sort((messageA, messageB) => messageA.id - messageB.id);
  }

  readMessage(id) {
    this.socket.send(types.READ_MESSAGE, { id });
  }

  sendMessage(text, backgroundFiles) {
    if (!this.currentRoom) {
      this.lastMessages.push([text, backgroundFiles]);
      console.warn('[ChatService] Send message error. Not in a room');
      return;
    }

    this.socket.send(types.SEND_MESSAGE, { text, backgroundFiles });
  }

  resendLastMessages() {
    this.lastMessages.forEach(lastMessage => {
      this.sendMessage(...lastMessage, false);
    });

    this.sendMessages = [];
  }

  createRoom(userId) {
    return client
      .post(`${BASE_URL}/employees/rooms/`, {
        client: userId,
      })
      .then(room => {
        return this.normalizeRoomData(room);
      });
  }

  translateMessage(messageId, targetLangId) {
    this.socket.send(types.TRANSLATE_MESSAGE, { id: messageId, targetLangId });
  }
}

export default new ChatService();
