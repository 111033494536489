import { AbilityBuilder, Ability } from '@casl/ability';
import * as roles from '@/constants/roles';
import * as subjects from '@/constants/subjects';
import * as actions from '@/constants/actions';

export function defineRulesFor(role, rights) {
  const { can, rules } = new AbilityBuilder();

  if (role === roles.CLIENT) {
    can(actions.READ, subjects.CARDS);
    can(actions.READ, subjects.INVOICES);
    can(actions.READ, subjects.CHAT);
    can(actions.READ, subjects.PROFILE);
    can(actions.READ, subjects.INVOICES_SHOW_UNPAID);
    can(actions.PAY, subjects.INVOICES);
    can(actions.READ, subjects.CLIENT_ROOM);
    can(actions.READ, subjects.NEW_INVOICES_COUNT);
    can(actions.READ, subjects.ANNOUNCEMENTS);
  } else if (role === roles.MANAGEMENT_COMPANY_EMPLOYEE) {
    can(actions.READ, subjects.PROFILE);
    Object.entries(rights).forEach(([subject, { read, write }]) => {
      if (read) {
        can(actions.READ, subject);
      }

      if (write) {
        can(actions.UPDATE, subject);
      }
    });
  }

  return rules;
}

export default function defineAbilityFor(role, rights) {
  return new Ability(defineRulesFor(role, rights));
}
