import { camelToSnake, snakeToCamel, snakeCaseKeysToCamelCase, camelCaseKeysToSnakeCase } from '@/utils/formatters';
import authService from '@/services/auth';

/**
 * @typedef {object} Options
 * @property {boolean} once
 */

/**
 * @typedef {(message: unknown) => void} wsCb
 */

/**
 * @typedef {object} Emit
 * @property {wsCb} cb
 * @property {Options=} options
 */

export default class WS {
  /** @type {WebSocket} */
  wsInstance = null;

  /** @type {Object.<string, Emit[]>} */
  typesCallbacks = {};

  /**
   * @param {string} url
   */
  constructor(url) {
    this.initWebsocket(url);
  }

  /**
   * @param {string} type
   * @param {wsCb} cb
   * @param {Options} options
   */
  on(type, cb, options) {
    this.initCallbackType(type);

    this.addListener(type, cb, options);
  }

  /**
   * @param {string} url
   */
  initWebsocket(url) {
    return new Promise(resolve => {
      if (this.wsInstance) {
        console.warn('[Websocket] Instance already exists');
        return;
      }

      this.wsInstance = new WebSocket(url);

      this.wsInstance.addEventListener('error', message => {
        this.emit('error', message);
      });

      this.wsInstance.addEventListener('open', () => {
        this.emit('open');
        resolve();
      });

      this.wsInstance.addEventListener('message', message => {
        const jsonMessage = JSON.parse(message.data);
        const originMessage = { ...jsonMessage };

        delete originMessage.type;

        this.emit(snakeToCamel(jsonMessage.type), snakeCaseKeysToCamelCase(originMessage));
      });
    });
  }

  /**
   * @param {string} type
   */
  initCallbackType(type) {
    if (!this.typesCallbacks[type]) {
      this.typesCallbacks[type] = [];
    }
  }

  removeInstance() {
    this.wsInstance.close();
    this.wsInstance = null;
  }

  /**
   * @param {string} type
   * @param {string} message
   */
  async send(type, message) {
    if (this.wsInstance.readyState !== this.wsInstance.OPEN) {
      const oldReconnectUrl = this.wsInstance.url;

      await authService.refreshAuthTokens();
      const newReconnectUrl = `${oldReconnectUrl.split('?')[0]}?token=${authService.access}`;

      this.removeInstance();
      await this.initWebsocket(newReconnectUrl);
    }

    this.wsInstance.send(JSON.stringify({ ...camelCaseKeysToSnakeCase(message), type: camelToSnake(type) }));
  }

  /**
   * @param {string} type
   * @param {unknown} message
   */
  emit(type, message) {
    this.typesCallbacks[type]?.forEach(typeCallback => {
      typeCallback.cb(message);

      if (typeCallback?.options?.once) {
        this.removeListener(type, typeCallback.cb);
      }
    });
  }

  /**
   * @param {string} type
   * @param {wsCb} cb
   * @param {Options} options
   */
  addListener(type, cb, options = {}) {
    this.typesCallbacks[type].push({ cb, options });
  }

  /**
   * @param {string} type
   * @param {wsCb} cb
   */
  removeListener(type, cb) {
    this.typesCallbacks[type] = this.typesCallbacks[type]?.filter(typeCallback => typeCallback.cb !== cb);
  }
}
