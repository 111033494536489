export default {
  localeList: {
    loading: false,
    params: {},
    instance: [],
  },

  localeModel: {
    loading: false,
    params: {},
    instance: {},
  },
};
